import React from 'react'
import propTypes from 'prop-types'
import { container } from './styles.module.scss'

const Container = props => {
  return (
    <div {...props} className={`${container} ${props.className || ''}${props.withBackground ? ' with-background' : ''}`}>
      <div className={`container-inner ${props.columns ? `columns-${props.columns}` : ''} ${props.debug ? ' debug' : ''} ${props.size ? props.size : ''}`}>{props.children}</div>
    </div>
  )
}

Container.propTypes = {
  size: propTypes.string,
  className: propTypes.string,
}

export default Container
